import { template as template_6ad9395c0cdd48e4b2289cb1d3bb6e82 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_6ad9395c0cdd48e4b2289cb1d3bb6e82(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
