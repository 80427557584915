import { template as template_fe0bca57c30d4fb8bafd1fbcaac22712 } from "@ember/template-compiler";
const FKText = template_fe0bca57c30d4fb8bafd1fbcaac22712(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
