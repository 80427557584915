import { template as template_4ef4fc6f5da148d8a280f242dd4b04c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4ef4fc6f5da148d8a280f242dd4b04c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
