import { template as template_72e4f45169684dbe8314634df96a9fdb } from "@ember/template-compiler";
const SidebarSectionMessage = template_72e4f45169684dbe8314634df96a9fdb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
