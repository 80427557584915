import { template as template_6f93f177324742edb2ada040ed7840ce } from "@ember/template-compiler";
const FKLabel = template_6f93f177324742edb2ada040ed7840ce(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
